/************************************************************
*  コラムページの固有のcssを定義
*************************************************************/
@use '../../global' as *;

/* ---------------------------------------------------------------------------------------
	アーカイブ
--------------------------------------------------------------------------------------- */

.p-column {
    padding: clamp(50px,10vw,100px) 0 clamp(50px,20vw,200px);
}

.p-search-box {
    margin: 0 auto clamp(40px, 6vw, 60px);
    padding-bottom: 10px;
    border-bottom: 1px solid $bk8;

    &__form {
        width: 700px;
        max-width: 100%;
        margin: 0 auto clamp(20px, 3vw, 30px);
        display: flex;
        border: 1px solid $bk8;
        border-radius: 3px;
        overflow: hidden;

        &-txt {
            width: 640px;
            padding: 0 20px;
            font-size: 15px;
            border: none;
            outline: none;

            &::placeholder {
                color: $bk6;
            }

            @include mq(sp) {
                width: calc(100% - 60px);
            }
        }

        &-button {
            width: 60px;
            height: 50px;
            cursor: pointer;
            border: none;
            outline: none;
            border-radius: 0;
            background: url(../images/column/search_icon.png) no-repeat 50% 50%;
            background-size: 27px auto;
        }
    }
}

.p-column-tag {
    display: flex;
    align-items: baseline;

    &--left {
        justify-content: flex-end;
    }

    @include mq(sp) {
        display: block;
    }

    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &-item {
            margin: 0 3px 3px 0;
        }

        &-item:last-child &-link::after {
            display: none;
        }

        &-link {
            font-size: clamp(13px, 3.5vw, 15px);
            color: $br15;
            display: flex;
            align-items: center;
            position: relative;
            transition: all 0.3s ease 0s;

            &:hover {
                // opacity: .7;
                text-decoration: underline;
            }

            &::after {
                content: ',';
            }
        }
    }


    &__list02 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        &-item {
            text-align: center;
            background: $br9;
            color: $bk1;
            margin: 0 5px 5px 0;

            >a{
                font-size: clamp(13px, 3.5vw, 15px);
                font-weight: 600;
                color: $br15;
                background-color: $br9;
                padding: 3px;
                transition: all .35s;
                display: block;
                min-width: 100px;
                min-height: clamp(20px,3vw,30px);

                &:hover{
                    background: darken($br9,25%);
                    color: $wh1;
                }
            }
        }
    }

    &__txt {
        margin: 0 10px 10px 0;
        display: inline-block;
        min-width: 100px;
        min-height: clamp(20px,3vw,30px);
        text-align: center;
        background: $br9;
        color: $br15;
        padding: 3px;
        font-size: clamp(13px,3.5vw,15px);
    }
}

.p-column-cont {
    margin: 0 auto;

    @include mq(sp) {
        display: block;
    }
}

/************************************************************
*  投稿タグリスト のスタイルを定義
*************************************************************/

.c-category-tag {
    &__list {
        display: flex;
        flex-wrap: wrap;

        &-item {
            min-width: clamp(70px, 10vw, 100px);
            padding: clamp(.3em, 1vw, .5em);
            background-color: $pi1;
            text-align: center;
            color: $wh1;
            font-size: 10px;
            line-height: 1;
            margin-right: 10px;

            &--column {
                min-width: clamp(50px,6vw,60px);
            }
        }
    }
}

.p-article {
    &-list {
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: $bp1100) {
            justify-content: space-between;
        }

        &__item {
            width: calc(calc(100% - 60px)/3);
            margin: 0 clamp(20px, 3vw, 30px) clamp(20px, 3vw, 30px) 0;
            box-shadow: 1px 1px 20px rgba($br16,80%);
            background: $wh1;
            overflow: hidden;
            transition: .35s ease;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: $bp1100) {
                width: calc(calc(100% - clamp(20px, 3vw, 30px))/2);
            }

            &:hover {
                transform: translateY(-20px);
                // box-shadow: 0px 15px 20px rgba($br1,20%);

                @include mq(sp){
                    transform: translateY(-10px);
                    // box-shadow: 0px 10px 20px rgba($br1,20%);
                }
            }

            &:nth-child(3n) {
                margin-right: 0px;

                @media screen and (max-width: $bp1100) {
                    margin-right: clamp(20px, 3vw, 30px);
                }
            }

            @media screen and (max-width: $bp1100) {
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }

    &-txt {
        padding: clamp(10px, 2vw, 20px);
        flex-grow: 1;

        &__cont {
            &-ttl {
                font-size: clamp(16px,3.5vw,20px);
                font-weight: 600;
                line-height: 1.2;
                color: $br15;
                margin-bottom: 10px;
            }
        }
        &__inner {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
        }
        p {
            font-size: clamp(13px, 3.5vw, 15px);
            color: $br15;
        }
    }
}

/* ---------------------------------------------------------------------------------------
	シングル
--------------------------------------------------------------------------------------- */

.p-singlecolumn {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    // padding-top: clamp(30px,7vw,70px);
    // padding-bottom: clamp(50px,20vw,200px);

    @include mq(tab) {
        width: 100%;
        display: block;
    }

    &__inner {
        width: calc(calc(100% - clamp(30px, 5vw, 50px)) - clamp(250px, 30vw, 300px));
        max-width: 750px;
        margin-right: clamp(30px, 5vw, 50px);

        @include mq(tab) {
            width: 100%;
            margin: 0 auto;
        }
    }

    &__main {
        &-ttl {
            font-size: clamp(20px, 4vw, 36px);
            font-weight: 600;
            color: $bk3;
            margin-bottom: 10px;
        }
    }

    /* 本文汎用パーツ */
    &-outer01 {
        margin-bottom: clamp(40px, 7vw, 70px);
    }

    &-outer02+&-outer02 {
        margin-top: clamp(40px, 7vw, 70px);
    }

    &-outer03 {
        margin-bottom: clamp(20px, 5vw, 50px);
    }

    &__txt01 {
        font-size: clamp(13px, 3.5vw, 15px);
        line-height: $line-height-20;
        p+p {
            margin-top: 20px;
        }
    }

    &__txt02 {
        font-size: clamp(13px, 3.5vw, 15px);
        font-weight: 500;
        line-height: 2;
        width: calc(calc(100% - 30px) - 34.6%);
        margin-left: 30px;
        line-height: $line-height-20;

        @include mq(sp) {
            width: 100%;
            margin: 0;
        }
    }

    &__img01 {
        margin-bottom: clamp(20px,4vw,40px);
    }

    &__img02 {
        width: 34.6%;
        max-width: 100%;

        @include mq(sp) {
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
        }
    }

    &__box01 {
        padding: clamp(10px, 2vw, 20px);
        background-color: $br9;
    }

    &__flex01 {
        display: flex;
        &+&{
            margin-top: 30px;
        }
        @include mq(sp) {
            flex-direction: column;
        }
    }

    /* コラムボタン */
    &__btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 30px;
        margin: 30px 0;
        border-bottom: 1px solid $bk8;
        position: relative;

        @include mq(sp) {
            padding-top: 40px;
        }

        &-item {
            max-width: clamp(140px,20vw,150px);
            width: 100%;
            min-height: 25px;
            text-align: center;
            transition: all .35s;

            >a {
                font-size: clamp(13px, 3.5vw, 15px);
                font-weight: 600;
            }
            &--btn {
                &:hover {
                    color: $pi2;
                    text-decoration: underline;
                    text-decoration-color: $pi2;
                }
                @include mq(sp) {
                    position: absolute;
                    text-align: center;
                    width: 100%;
                    top: calc(50% - 40px);
                    left: 50%;
                    width: 100%;
                    transform: translateY(-50%) translateX(-50%);
                }
            }
            &--prev,
            &--next {
                >a {
                    >p {
                        position: relative;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            background: url(../images/column/arrow_btn02.png) no-repeat;
                            background-size: 100%;
                            top: calc(100% - 23px);
                            width: 22px;
                            height: 22px;
                            transition: all ease .3s;
                        }
                    }
                }
            }

            &--prev {
                >a {
                    &:hover {
                        >p {
                            &::before {
                                left: -3px;
                            }
                        }
                    }

                    >p {
                        padding-left: 30px;

                        &::before {
                            left: 0;
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            &--next {
                >a {
                    &:hover {
                        >p {
                            &::before {
                                right: -3px;
                            }
                        }
                    }

                    >p {
                        padding-right: 30px;

                        &::before {
                            right: 0;
                        }
                    }
                }
            }

        }
    }

    /* タグ一覧 */
    &-category {
        &__inner {
            border: 1px solid $bk8;
            padding: 20px;
        }

        &__ttl {
            margin-bottom: 10px;
            font-size: clamp(20px, 3.5vw, 24px);
            color: $br15;
        }
    }

}

/* ---------------------------------------------------------------------------------------
	サイドバー 目次
--------------------------------------------------------------------------------------- */

.p-page-navi {
    width: clamp(250px, 30vw, 300px);
    max-width: 300px;
    position: sticky;
    top: 100px;

    @include mq(tab) {
        width: 100%;
        max-width: 100%;
        position: unset;
        margin-top: clamp(40px, 7vw, 70px);
    }

    /*スクロールバー記述*/
    &__sidebar {
        overflow-y: scroll;
        max-height: calc(100vh - 300px);
        cursor: pointer;
        position: relative;
        background: $br10;
        box-shadow: 0px 0px 10px rgba($br1, 25%);
        border: 10px $wh1 solid;
        margin-bottom: 15px;

        @include mq(tab) {
            overflow-y: unset;
            max-height: 100%;

        }

        /*スクロールバーサイズ*/
        &::-webkit-scrollbar {
            width: 8px;
        }

        /*スクロールバーつまみ*/
        &::-webkit-scrollbar-thumb {
            background: $br5;
            border-radius: 8px;
        }

        /*スクロールバー*/
        &::-webkit-scrollbar-track {
            background: $br9;
            border-radius: 10px;
        }

        &-inner {
            padding: 10px;
            margin: 0 2px;
        }
    }

    // inner内の要素振り直し
    &__cont {
        &-ttl {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            padding-bottom: 8px;
            color: $br15;
            text-align: center;
        }
    }

    &__list {

        &,
        ul{
            counter-reset: ul-counter;
        }

        &,
        >li {
            margin-bottom: 0;
        }

        &-item + &-item,
        a + ul{
            margin-top: 2px;
        }

        li {
            a {
                font-size: clamp(13px,3.5vw,15px);
                font-weight: 600;
                position: relative;
                display: flex;

                &::before {
                    counter-increment: ul-counter;
                    content: counters(ul-counter,".")".";
                    // padding-right: .3em;
                    display: block;
                }

                &:hover {
                    span {
                        color: $pi1;
                        text-decoration: underline
                    }
                }
            }
        }

        // p-page-navi__list直下のli
        & &-item {
            >a {
                &::before {
                    content: counters(ul-counter, ".")".";
                }
            }

            // h3も追加する場合コメントアウト
            // > ul {
            //     padding-left: clamp(1rem,1.5vw,1.5rem);
            //     li {
            //         a{
            //             &::before {
            //                 content: counters(ul-counter,"-")".";
            //                 min-width: 35px;
            //                 // padding-right: .3em;
            //             }
            //         }
            //     }
            // }
        }
    }
}

// ページネーション用
.p-column-pagenavi {
    >ul {
        display: flex;
        justify-content: center;
        align-items: center;

        >li {
            font-size: clamp(13px, 3.5vw, 15px);
            font-weight: 600;
            line-height: 2.5;

            >a,
            span.current {
                width: clamp(30px, 4vw, 40px);
                height: clamp(30px, 4vw, 40px);
                margin: 5px;
                font-weight: 600;
                transition: all ease .3s;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            >a {
                border: 1px solid $br1;
                color: $br15;
            }

            >a:hover {
                background: $br9;
            }

            span.current {
                background-color: $br15;
                color: $wh1;
                border: 1px solid $br15;
            }

            >.next,
            >.prev {
                width: auto;
                height: auto;
                position: relative;
                border: none;
                color: $bk1;

                &:hover {
                    background: transparent;
                }

                &::before {
                    @include commonBtnBefore(22px, 22px, calc(50% - 12px), 0px, $transition: true);
                    @include commonBtn01($path: '../images/column/arrow_btn02.png', $size: 100% auto);
                }
            }

            >.next {
                padding-right: 25px;
                margin-left: 15px;

                &:hover {
                    &::before {
                        right: -3px;
                    }
                }
            }

            >.prev {
                padding-left: 25px;
                margin-right: 15px;

                &:hover {
                    &::before {
                        left: -3px;
                    }
                }

                &::before {
                    left: 0;
                    transform: rotate(180deg);
                }
            }
        }
    }
}