@charset "UTF-8";
/************************************************************
*  変数を定義
*************************************************************/
@media screen and (max-width: 1300px) {
  body {
    margin: 0;
  }
}
@media screen and (max-width: 1100px) {
  body {
    margin: 0;
  }
}
@media screen and (max-width: 1023px) {
  body {
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  body {
    margin: 0;
  }
}

/************************************************************
*  関数を定義
*************************************************************/
/************************************************************
*  mixinを定義
*************************************************************/
/* 共通ボタン
--------------------------------------------------------------------------------------- */
/* 背景色
--------------------------------------------------------------------------------------- */
/* ページ読み込み時にフェード
--------------------------------------------------------------------------------------- */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* 展開アニメーション
--------------------------------------------------------------------------------------- */
@keyframes qaOpen {
  0% {
    opacity: 0;
    transform: translateY(-10px);
    /* 上から表示 */
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
/* Animation 複数回使うなら/global */
@keyframes fadeBottomAnime {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/************************************************************
*  Global
*  汎用的な関数や変数を格納する
*************************************************************/
/************************************************************
*  コラムページの固有のcssを定義
*************************************************************/
/* ---------------------------------------------------------------------------------------
	アーカイブ
--------------------------------------------------------------------------------------- */
.p-column {
  padding: clamp(50px, 10vw, 100px) 0 clamp(50px, 20vw, 200px);
}

.p-search-box {
  margin: 0 auto clamp(40px, 6vw, 60px);
  padding-bottom: 10px;
  border-bottom: 1px solid #B3B3B3;
}
.p-search-box__form {
  width: 700px;
  max-width: 100%;
  margin: 0 auto clamp(20px, 3vw, 30px);
  display: flex;
  border: 1px solid #B3B3B3;
  border-radius: 3px;
  overflow: hidden;
}
.p-search-box__form-txt {
  width: 640px;
  padding: 0 20px;
  font-size: 15px;
  border: none;
  outline: none;
}
.p-search-box__form-txt::placeholder {
  color: #808080;
}
@media screen and (max-width: 767px) {
  .p-search-box__form-txt {
    width: calc(100% - 60px);
  }
}
.p-search-box__form-button {
  width: 60px;
  height: 50px;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 0;
  background: url(../images/column/search_icon.png) no-repeat 50% 50%;
  background-size: 27px auto;
}

.p-column-tag {
  display: flex;
  align-items: baseline;
}
.p-column-tag--left {
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .p-column-tag {
    display: block;
  }
}
.p-column-tag__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.p-column-tag__list-item {
  margin: 0 3px 3px 0;
}
.p-column-tag__list-item:last-child .p-column-tag__list-link::after {
  display: none;
}
.p-column-tag__list-link {
  font-size: clamp(13px, 3.5vw, 15px);
  color: #71675B;
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease 0s;
}
.p-column-tag__list-link:hover {
  text-decoration: underline;
}
.p-column-tag__list-link::after {
  content: ",";
}
.p-column-tag__list02 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.p-column-tag__list02-item {
  text-align: center;
  background: #F5F1EE;
  color: #000000;
  margin: 0 5px 5px 0;
}
.p-column-tag__list02-item > a {
  font-size: clamp(13px, 3.5vw, 15px);
  font-weight: 600;
  color: #71675B;
  background-color: #F5F1EE;
  padding: 3px;
  transition: all 0.35s;
  display: block;
  min-width: 100px;
  min-height: clamp(20px, 3vw, 30px);
}
.p-column-tag__list02-item > a:hover {
  background: #c6af9e;
  color: #FFFFFF;
}
.p-column-tag__txt {
  margin: 0 10px 10px 0;
  display: inline-block;
  min-width: 100px;
  min-height: clamp(20px, 3vw, 30px);
  text-align: center;
  background: #F5F1EE;
  color: #71675B;
  padding: 3px;
  font-size: clamp(13px, 3.5vw, 15px);
}

.p-column-cont {
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-column-cont {
    display: block;
  }
}

/************************************************************
*  投稿タグリスト のスタイルを定義
*************************************************************/
.c-category-tag__list {
  display: flex;
  flex-wrap: wrap;
}
.c-category-tag__list-item {
  min-width: clamp(70px, 10vw, 100px);
  padding: clamp(0.3em, 1vw, 0.5em);
  background-color: #F08984;
  text-align: center;
  color: #FFFFFF;
  font-size: 10px;
  line-height: 1;
  margin-right: 10px;
}
.c-category-tag__list-item--column {
  min-width: clamp(50px, 6vw, 60px);
}

.p-article-list {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 1100px) {
  .p-article-list {
    justify-content: space-between;
  }
}
.p-article-list__item {
  width: calc((100% - 60px) / 3);
  margin: 0 clamp(20px, 3vw, 30px) clamp(20px, 3vw, 30px) 0;
  box-shadow: 1px 1px 20px rgba(217, 214, 210, 0.8);
  background: #FFFFFF;
  overflow: hidden;
  transition: 0.35s ease;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1100px) {
  .p-article-list__item {
    width: calc((100% - clamp(20px, 3vw, 30px)) / 2);
  }
}
.p-article-list__item:hover {
  transform: translateY(-20px);
}
@media screen and (max-width: 767px) {
  .p-article-list__item:hover {
    transform: translateY(-10px);
  }
}
.p-article-list__item:nth-child(3n) {
  margin-right: 0px;
}
@media screen and (max-width: 1100px) {
  .p-article-list__item:nth-child(3n) {
    margin-right: clamp(20px, 3vw, 30px);
  }
}
@media screen and (max-width: 1100px) {
  .p-article-list__item:nth-child(2n) {
    margin-right: 0;
  }
}
.p-article-txt {
  padding: clamp(10px, 2vw, 20px);
  flex-grow: 1;
}
.p-article-txt__cont-ttl {
  font-size: clamp(16px, 3.5vw, 20px);
  font-weight: 600;
  line-height: 1.2;
  color: #71675B;
  margin-bottom: 10px;
}
.p-article-txt__inner {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.p-article-txt p {
  font-size: clamp(13px, 3.5vw, 15px);
  color: #71675B;
}

/* ---------------------------------------------------------------------------------------
	シングル
--------------------------------------------------------------------------------------- */
.p-singlecolumn {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* 本文汎用パーツ */
  /* コラムボタン */
  /* タグ一覧 */
}
@media screen and (max-width: 1023px) {
  .p-singlecolumn {
    width: 100%;
    display: block;
  }
}
.p-singlecolumn__inner {
  width: calc(100% - clamp(30px, 5vw, 50px) - clamp(250px, 30vw, 300px));
  max-width: 750px;
  margin-right: clamp(30px, 5vw, 50px);
}
@media screen and (max-width: 1023px) {
  .p-singlecolumn__inner {
    width: 100%;
    margin: 0 auto;
  }
}
.p-singlecolumn__main-ttl {
  font-size: clamp(20px, 4vw, 36px);
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}
.p-singlecolumn-outer01 {
  margin-bottom: clamp(40px, 7vw, 70px);
}
.p-singlecolumn-outer02 + .p-singlecolumn-outer02 {
  margin-top: clamp(40px, 7vw, 70px);
}
.p-singlecolumn-outer03 {
  margin-bottom: clamp(20px, 5vw, 50px);
}
.p-singlecolumn__txt01 {
  font-size: clamp(13px, 3.5vw, 15px);
  line-height: 2;
}
.p-singlecolumn__txt01 p + p {
  margin-top: 20px;
}
.p-singlecolumn__txt02 {
  font-size: clamp(13px, 3.5vw, 15px);
  font-weight: 500;
  line-height: 2;
  width: calc(100% - 30px - 34.6%);
  margin-left: 30px;
  line-height: 2;
}
@media screen and (max-width: 767px) {
  .p-singlecolumn__txt02 {
    width: 100%;
    margin: 0;
  }
}
.p-singlecolumn__img01 {
  margin-bottom: clamp(20px, 4vw, 40px);
}
.p-singlecolumn__img02 {
  width: 34.6%;
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .p-singlecolumn__img02 {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}
.p-singlecolumn__box01 {
  padding: clamp(10px, 2vw, 20px);
  background-color: #F5F1EE;
}
.p-singlecolumn__flex01 {
  display: flex;
}
.p-singlecolumn__flex01 + .p-singlecolumn__flex01 {
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .p-singlecolumn__flex01 {
    flex-direction: column;
  }
}
.p-singlecolumn__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
  margin: 30px 0;
  border-bottom: 1px solid #B3B3B3;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-singlecolumn__btn {
    padding-top: 40px;
  }
}
.p-singlecolumn__btn-item {
  max-width: clamp(140px, 20vw, 150px);
  width: 100%;
  min-height: 25px;
  text-align: center;
  transition: all 0.35s;
}
.p-singlecolumn__btn-item > a {
  font-size: clamp(13px, 3.5vw, 15px);
  font-weight: 600;
}
.p-singlecolumn__btn-item--btn:hover {
  color: #C77765;
  text-decoration: underline;
  text-decoration-color: #C77765;
}
@media screen and (max-width: 767px) {
  .p-singlecolumn__btn-item--btn {
    position: absolute;
    text-align: center;
    width: 100%;
    top: calc(50% - 40px);
    left: 50%;
    width: 100%;
    transform: translateY(-50%) translateX(-50%);
  }
}
.p-singlecolumn__btn-item--prev > a > p, .p-singlecolumn__btn-item--next > a > p {
  position: relative;
}
.p-singlecolumn__btn-item--prev > a > p::before, .p-singlecolumn__btn-item--next > a > p::before {
  content: "";
  display: block;
  position: absolute;
  background: url(../images/column/arrow_btn02.png) no-repeat;
  background-size: 100%;
  top: calc(100% - 23px);
  width: 22px;
  height: 22px;
  transition: all ease 0.3s;
}
.p-singlecolumn__btn-item--prev > a:hover > p::before {
  left: -3px;
}
.p-singlecolumn__btn-item--prev > a > p {
  padding-left: 30px;
}
.p-singlecolumn__btn-item--prev > a > p::before {
  left: 0;
  transform: rotate(180deg);
}
.p-singlecolumn__btn-item--next > a:hover > p::before {
  right: -3px;
}
.p-singlecolumn__btn-item--next > a > p {
  padding-right: 30px;
}
.p-singlecolumn__btn-item--next > a > p::before {
  right: 0;
}
.p-singlecolumn-category__inner {
  border: 1px solid #B3B3B3;
  padding: 20px;
}
.p-singlecolumn-category__ttl {
  margin-bottom: 10px;
  font-size: clamp(20px, 3.5vw, 24px);
  color: #71675B;
}

/* ---------------------------------------------------------------------------------------
	サイドバー 目次
--------------------------------------------------------------------------------------- */
.p-page-navi {
  width: clamp(250px, 30vw, 300px);
  max-width: 300px;
  position: sticky;
  top: 100px;
  /*スクロールバー記述*/
}
@media screen and (max-width: 1023px) {
  .p-page-navi {
    width: 100%;
    max-width: 100%;
    position: unset;
    margin-top: clamp(40px, 7vw, 70px);
  }
}
.p-page-navi__sidebar {
  overflow-y: scroll;
  max-height: calc(100vh - 300px);
  cursor: pointer;
  position: relative;
  background: #F7F5F3;
  box-shadow: 0px 0px 10px rgba(171, 149, 132, 0.25);
  border: 10px #FFFFFF solid;
  margin-bottom: 15px;
  /*スクロールバーサイズ*/
  /*スクロールバーつまみ*/
  /*スクロールバー*/
}
@media screen and (max-width: 1023px) {
  .p-page-navi__sidebar {
    overflow-y: unset;
    max-height: 100%;
  }
}
.p-page-navi__sidebar::-webkit-scrollbar {
  width: 8px;
}
.p-page-navi__sidebar::-webkit-scrollbar-thumb {
  background: #CDC0B5;
  border-radius: 8px;
}
.p-page-navi__sidebar::-webkit-scrollbar-track {
  background: #F5F1EE;
  border-radius: 10px;
}
.p-page-navi__sidebar-inner {
  padding: 10px;
  margin: 0 2px;
}
.p-page-navi__cont-ttl {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 8px;
  color: #71675B;
  text-align: center;
}
.p-page-navi__list,
.p-page-navi__list ul {
  counter-reset: ul-counter;
}
.p-page-navi__list,
.p-page-navi__list > li {
  margin-bottom: 0;
}
.p-page-navi__list-item + .p-page-navi__list-item,
.p-page-navi__list a + ul {
  margin-top: 2px;
}
.p-page-navi__list li a {
  font-size: clamp(13px, 3.5vw, 15px);
  font-weight: 600;
  position: relative;
  display: flex;
}
.p-page-navi__list li a::before {
  counter-increment: ul-counter;
  content: counters(ul-counter, ".") ".";
  display: block;
}
.p-page-navi__list li a:hover span {
  color: #F08984;
  text-decoration: underline;
}
.p-page-navi__list .p-page-navi__list-item > a::before {
  content: counters(ul-counter, ".") ".";
}

.p-column-pagenavi > ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-column-pagenavi > ul > li {
  font-size: clamp(13px, 3.5vw, 15px);
  font-weight: 600;
  line-height: 2.5;
}
.p-column-pagenavi > ul > li > a,
.p-column-pagenavi > ul > li span.current {
  width: clamp(30px, 4vw, 40px);
  height: clamp(30px, 4vw, 40px);
  margin: 5px;
  font-weight: 600;
  transition: all ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-column-pagenavi > ul > li > a {
  border: 1px solid #AB9584;
  color: #71675B;
}
.p-column-pagenavi > ul > li > a:hover {
  background: #F5F1EE;
}
.p-column-pagenavi > ul > li span.current {
  background-color: #71675B;
  color: #FFFFFF;
  border: 1px solid #71675B;
}
.p-column-pagenavi > ul > li > .next,
.p-column-pagenavi > ul > li > .prev {
  width: auto;
  height: auto;
  position: relative;
  border: none;
  color: #000000;
}
.p-column-pagenavi > ul > li > .next:hover,
.p-column-pagenavi > ul > li > .prev:hover {
  background: transparent;
}
.p-column-pagenavi > ul > li > .next::before,
.p-column-pagenavi > ul > li > .prev::before {
  content: "";
  width: 22px;
  height: 22px;
  display: block;
  position: absolute;
  top: calc(50% - 12px);
  right: 0px;
  transition: all 0.35s;
  background: url("../images/column/arrow_btn02.png") no-repeat 0 0;
  background-size: 100% auto;
}
.p-column-pagenavi > ul > li > .next {
  padding-right: 25px;
  margin-left: 15px;
}
.p-column-pagenavi > ul > li > .next:hover::before {
  right: -3px;
}
.p-column-pagenavi > ul > li > .prev {
  padding-left: 25px;
  margin-right: 15px;
}
.p-column-pagenavi > ul > li > .prev:hover::before {
  left: -3px;
}
.p-column-pagenavi > ul > li > .prev::before {
  left: 0;
  transform: rotate(180deg);
}

/************************************************************
*  コラムページcss
*************************************************************/