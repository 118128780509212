/************************************************************
*  変数を定義
*************************************************************/
// color

$bk1: #000000;
$bk2: #1A1A1A; // 1a1a1a
$bk3: #333333;
$bk4: #4D4D4D; // 4d4d4d
$bk5: #666666;
$bk6: #808080;
$bk7: #999999;
$bk8: #B3B3B3; // b3b3b3
$bk9: #E6E6E6; // e6e6e6
$bk10: #2D2D2D; // 2d2d2d
$bk11: #6B6B6B; // 6b6b6b
$bk12: #757575;
$bk13: #232323;
$bk14: #D8D8D8; // d8d8d8
$bk15: #E1E1E1; // e1e1e1
$bk16: #8F8F8F; // 8f8f8f
$bk17: #131313;
$bk18: #404040;
$bk19: #ECECEC; // ececec
$bk20: #A5A5A5; // a5a5a5


$br1: #AB9584; // ab9584 使っている
// $br2: #B4A091; // b4a091
// $br3: #BCAA9D; // bcaa9d
// $br4: #C5B5A9; // c5b5a9
$br5: #CDC0B5; // cdc0b5 使っている
// $br6: #D5CAC1; // d5cac1
// $br7: #DDD4CE; // ddd4ce
// $br8: #E6DFDA; // e6dfda
$br9: #F5F1EE; // f5f1ee 使っている
$br10: #F7F5F3; // f7f5f3
// $br11: #65320F; // 65320f
// $br12: #653612; // 653612
// $br13: #DCD0C7; // dcd0c7
$br14: #503D2F; // 503d2f 使っている
$br15: #71675B; // 71675b 使っている
$br16: #D9D6D2; // d9d6d2 使っている

$wh1:#FFFFFF; // fffffff

$pi1: #F08984; // f08984
$pi2: #C77765; // c77765

$bl1: #CDDDF5; // cdddf5


// font-family
$ff-yugoshic: 游ゴシック medium, yu gothic medium, 游ゴシック, yu gothic, メイリオ, meiryo, ヒラギノ角ゴ pro, hiragino kaku gothic pro, sans-serif;
$ff-yugoshic-bold: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif;
$ff-yumincho: '游明朝', 'Yu Mincho', YuMincho, 'Hiragino Mincho Pro', serif;

// line-height
$line-height-10:1.0;
$line-height-13:1.3;
$line-height-14:1.4;
$line-height-15:1.5;
$line-height-18:1.8;
$line-height-20:2.0;
$line-height-23:2.3;
$line-height-25:2.5;

// breakpoint
$bp_sp: 767px;
$bp_tab: 1023px;
$breakpoints: (
  'sp': 'screen and (max-width: 767px)',
  'tab': 'screen and (max-width: 1023px)',
);

$bp1300: 1300px;
$bp1100: 1100px;

// メディアクエリの順番を定義
body {
  @media screen and (max-width: $bp1300) {
    margin: 0;
  }
  @media screen and (max-width: $bp1100) {
    margin: 0;
  }
  @media screen and (max-width: $bp_tab) {
    margin: 0;
  }
  @media screen and (max-width: $bp_sp) {
    margin: 0;
  }
}
