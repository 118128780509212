/************************************************************
*  mixinを定義
*************************************************************/
@use 'variable' as *;
@use 'function' as *;

// メディアクエリのショートカット
// https://www.tam-tam.co.jp/tipsnote/html_css/post10708.html
@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin max-screen($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

/* 共通ボタン
--------------------------------------------------------------------------------------- */

@mixin commonBtnBefore($width, $height, $top, $right, $transition) {
  content: '';
  width: $width;
  height: $height;
  display: block;
  position: absolute;
  top: $top;
  right: $right;

  @if $transition ==true {
    transition: all .35s;
  }
}

@mixin commonBtn01($path, $size) {
  background: url($path) no-repeat 0 0;
  background-size: $size;
}

// 矢印

@mixin arrowBtn($size: 0.6em, $top: calc(50% - 6px),$sp_top: calc(50% - 6px), $right: 20px, $right-hover: 15px, $border: .13em) {
  &:hover{
    .c-btn__arrow {
      right: $right-hover;
    }
  }
  .c-btn__arrow {
    display: inline-block;
    vertical-align: middle;
    // color: $wh1;
    line-height: 1;
    position: absolute;
    width: $size;
    height: $size;
    transform: translateX(-25%) rotate(45deg);
    right: $right;
    top: $top;
    transition:all .35s;
    z-index: 4;

    @include mq(sp){
      top: $sp_top;
    }

    &::before,
    &::after {
      content: '';
      background: currentColor;
      position: absolute;
      border-radius: $border;
    }

    &::before {
      top: 0;
      left: 0;
      right: 0;
      height: $border;
    }

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      width: $border;
    }
  }
}

/* 背景色
--------------------------------------------------------------------------------------- */


/* ページ読み込み時にフェード
--------------------------------------------------------------------------------------- */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
}

/* 展開アニメーション
--------------------------------------------------------------------------------------- */
@keyframes qaOpen {
  0% {
    opacity: 0;
    transform: translateY(-10px);
    /* 上から表示 */
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

/* Animation 複数回使うなら/global */
@keyframes fadeBottomAnime {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}